import { useState } from 'react';
import {Button, Form, Modal, Table} from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent/index";
const AdminPanel = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return(
        <div className="page-block">
   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h4 mb-2 mx-1 text-start"> <span>An enterprise template to ramp <br/>up your company website</span></h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152 justify-content-center">
         <span>Add Widget</span>
      </Button>
   </div>
   <Table responsive bordered>
      <thead>
         <tr>
            <th>Game Name </th>
            <th>Name Of User</th>
            <th>Achive Target</th>
            <th>Total Points</th>
            <th>Edit Formate</th>
         </tr>
      </thead>
      <tbody>
         <tr>
            <td>Lorum Ipsum Name</td>
            <td>13</td>
            <td>Achive Target</td>
            <td>56</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" onClick={handleShow} className="px-1 d-inline-flex align-items-center justify-content-center text-primary"  onClick={handleShow}>
                     <SvgComponent width={20} height={20} viewBox='0 0 20 20' SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         <tr>
            <td>01.</td>
            <td>25-04-2023</td>
            <td>Game Name </td>
            <td>Status Type</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
      </tbody>
   </Table>

   <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-center h1 flex-grow-1'>Lorum Ipsum Content </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Form>

            <div>
               <Form.Group className="mb-30" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Question - 1" />
               </Form.Group>
               <Form.Group className="mb-30 with-icon" controlId="answer">
                  <Form.Control type="text" placeholder="Answer" />
                  <Button variant='link' className='ms-2 plus-btn d-inline-flex align-items-center justify-content-center'><img src="/assets/images/add-ring.svg" alt="add" /></Button>
               </Form.Group>
            </div>
            <Button variant="secondary" type="submit">
        Submit
      </Button>
            </Form>
        </Modal.Body>
      
      </Modal>
</div>
    )
}

export default AdminPanel;