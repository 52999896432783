import { Route } from "react-router-dom";
import Game from "../../pages/user/game";
import Login from "../../pages/user/Login";
import SignUp from "../../pages/user/SignUp";
import GameList from "../../pages/user/GameList";
import GameConfiguration from "../../pages/user/GameConfiguration";
import GameDetails from "../../pages/user/GameDetails";
import MainPage from "../../pages/user/MainPage";
import { UserLayout } from "../../pages/shared/layouts/UserLayout";
import StartGame from "../../pages/user/StartGame";
import VideoTour from "../../pages/user/VideoTour";
import VideoIntro from "../../pages/user/VideoIntro";
import File from "../../pages/user/File";
import Tc from "../../pages/user/Tc";
import History from "../../pages/user/History";
import Question from "../../pages/user/Question";
import TrueFalse from "../../pages/user/TrueFalse";
import CategoryDetails from "../../pages/user/CategoryDetails";
import { CallbackPage } from "../../pages/shared/layouts/Callbackpage";
export const userRoutes = (
    <>
    <Route path="/user" element={<UserLayout />}>
    <Route path='game' element ={<Game />} />
    
    <Route path='game-list' element ={<GameList />} />
    <Route path='game-configuartion' element ={<GameConfiguration />} />
    <Route path='category-details/:game_type' element ={<CategoryDetails />} />
    <Route path='game-details/:game_type/:series/:is_single' element ={<GameDetails />} /> 
    <Route path='main-page' element ={<MainPage />} />
    
    
    
   
    </Route>
    <Route path='login' element ={<Login />} />
    <Route path="/callback" element={<CallbackPage />} />

    <Route path='sign-up' element ={<SignUp />} />
    <Route path='start-game' element ={<StartGame />} />
    <Route path='video-intro' element ={<VideoIntro />} />
    <Route path='video-tour' element ={<VideoTour />} />
    <Route path='file' element ={<File />} />
    <Route path='tc' element ={<Tc />} />
    <Route path='history' element ={<History />} />
    <Route path='question' element ={<Question />} />
    <Route path='true-false' element ={<TrueFalse />} />
    
    </>
)