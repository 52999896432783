import Header from "../shared/layouts/header";
import {Button, Table} from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent/index";
import React, { useState, useEffect } from 'react';
import CreateGameModal from "./CreateGameModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getGamesOfOrganization } from '../../store/api/course.api';
import moment from 'moment'
import EditGame from "./EditGame";
import { useNavigate,Link } from 'react-router-dom';

const CreateGame = () =>{
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [allgames, setAllGames] = useState();
   const { organization_id } = useSelector((state) => state.auth.user || {});
   const [editId, setEditId] = useState(null);
   const [showEdit, setShowEdit] = useState(false);
   const navigate = useNavigate();
   const openShow = () => {
      setShow(true);
  }
   const callbackModal = () => {
      setShow(false);
      setShowEdit(false);
   }
   
   useEffect(() => {
      dispatch(getGamesOfOrganization(organization_id)).then((res) => {
         setAllGames(res.payload)
      })

  }, []);

  const openEdit = (id) => {
   setEditId(id);
   setShowEdit(true)
  
 }
 const openDetail = (id) => {
   navigate('/admin/game-detail/'+id);
 }
return (

   
<div className="page-block">
   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">An enterprise template to ramp <br/>up your company website</h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152" onClick={() => openShow()}>
         <SvgComponent SvgName="add" className="me-1" />
         <span>Add Game</span>
      </Button>
      {show &&
      <CreateGameModal show={show} callbackModal={callbackModal} ></CreateGameModal>
   }
   </div>
   <Table responsive bordered>
      <thead>
         <tr>
            <th>Game Name </th>
            <th> Game Type</th>
            <th>Language</th>
            <th>Duration</th>
            <th>Date</th>
            <th>Edit Formate</th>
         </tr>
      </thead>
      <tbody>
      {
         allgames && allgames.length > 0 && allgames?.map((item, index) => (
         <tr key={item?.id} >
            <td onClick={()=>openDetail(item?.id)}>{item.title}</td>
            <td onClick={()=>openDetail(item?.id)}>{item.game_type}</td>
            <td onClick={()=>openDetail(item?.id)}>{item.language}</td>
            <td onClick={()=>openDetail(item?.id)}>{item.duration} min</td>
            <td onClick={()=>openDetail(item?.id)}>{moment(item?.timestamp).format("DD-MMM-YYYY")}</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary" onClick={() => openEdit(item?.id)}>
                     <SvgComponent SvgName="edit" />
                  </Button>
                  {showEdit && editId === item?.id &&
                  <EditGame show={showEdit} editItem={item} callbackModal={callbackModal} />
                  }
               </div>
            </td>
         </tr>
       ))}
      </tbody>
   </Table>
</div>
)
}
export default CreateGame;