import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Image, Form} from 'react-bootstrap';
import SvgComponent from "../../../Components/SvgComponent/index";

const Header =() => {
    useEffect(() => {
    }, []);
return (<header className='bg-primary px-9'>
            <div className='conatiner'>
                <div className='d-flex align-items-center justify-content-between '>
                    <div className=' mb-3 logo-block text-center ms-n9'>
                        <Link to="/" className="logo ">
                        <SvgComponent width={'100%'} height={'100%'} viewBox='0 0 192 45' SvgName="logomain" className={'header-logo-full mt-3'}  />
                        </Link>
                    </div>
                    <div className='d-inline-flex align-items-center text-white'>
                        <div className="d-inline-flex align-items-center justify-content-center fw-medium me-11">
                            <span className='profile-image me-5'>
                            <Image src='/assets/images/avatar.png' alt="Jane S." className='w-100 h-100 object-fit rounded-pill' />
                            </span>
                            <span>Jane S.</span>
                        </div>
                        <Form className="d-flex position-relative searchform">
                            <SvgComponent SvgName="search" className="icon-bx position-absolute" width={20} height={20} viewBox='0 0 20 20' />
                            <Form.Control
                            type="search"
                            placeholder="Quick Search"
                            aria-label="Search"
                            className='bg-transparent text-white border-0'
                            />
                        </Form>
                    </div>
                </div>
            </div>
        </header>)
}

export default Header