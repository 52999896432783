import {Link} from 'react-router-dom';
import SvgComponent from "../../../Components/SvgComponent/index";
const Sidebar = () => {
    return(
        <aside className="bg-primary sidebar-wrap flex-shrink-0">
            <ul className="list-unstyled mb-0">
                <li>
                    <Link to="/" className='navigation-link fw-medium active'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="dashboard" />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/create" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="people" width="22" height="14" viewBox="0 0 22 14" />
                        <span>People</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/create" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="game"  />
                        <span>Create Game</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/create" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="gameListing"  />
                        <span>Game Listing</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/invitation" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="userListing"  />
                        <span>User Listing</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/leaderboard" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="gameListing"  />
                        <span>Setting</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/leaderboard" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="message"  />
                        <span>Message</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/leaderboard" className='navigation-link fw-medium'>
                        <SvgComponent className="navigation-ic me-7 flex-shrink-0" SvgName="notification"  />
                        <span>Notificatin</span>
                    </Link>
                </li>
            </ul>
        </aside>
    )
}

export default Sidebar;