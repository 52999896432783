import axios from "./httpService";
  const API_URL = axios.Api;

const getPublicContent = () => {
  return axios.get(API_URL + "booking/bookable");
};
const getGamesCategory = () => {
  return axios.get(API_URL + "gamescategory/games-category/");
};
const getGamesCategoryDetail = (id) => {
  return axios.get(API_URL + "gamescategory/games-category/"+id);
};
const getUserDetails = (body) => {

  return axios.post(API_URL + "core/get-user-details/",body);
};

const addGame = (body) => {
  return axios.post(API_URL + "gamescript/game/", body);
};
const editGame = (body) => {
  return axios.patch(API_URL + "gamescript/game/"+`${body.get("id")}/`, body);
};


const getGamesOfOrganization = (id) => {
  return axios.get(API_URL + "gamescript/game/?organization_id="+id);
};
const getCategorywiseGames = (id) => {
  return axios.get(API_URL + "gamescript/booked-games/?games_category="+id);
};
const addTips = (body) => {
  return axios.post(API_URL + "gamescript/tips/", body);
};
const addQuestion = (body) => {
  return axios.post(API_URL + "gamescript/final-questions/", body);
};
const getSingleGames = (id) => {
  return axios.get(API_URL + "gamescript/game/"+id+"/");
};
const getSingleGamesAttachment = (id) => {
  return axios.get(API_URL + "gamescript/attachment/?script="+id);
};
const getSingleGamesTip = (id) => {
  return axios.get(API_URL + "gamescript/tips/?script="+id);
};
const getSingleGamesFinalQuestion = (id) => {
  return axios.get(API_URL + "gamescript/final-questions/?script="+id);
};

const getGamesCategoryFag = (id) => {
  return axios.get(API_URL + "gamescategory/games-faq/?games_category="+id);
};
const getAllGames = (id) => {
  return axios.get(API_URL + "gamescript/booked-games/");
};
const getTrendingGames =() => {
  return axios.get(API_URL + "booking/trending-games");
}
const contactus = (body) => {
  return axios.post(API_URL + "core/contact-us/", body);
};
const userService = {
  getPublicContent,
  getGamesCategory,
  getGamesCategoryDetail,
  getCategorywiseGames,
  addGame,
  getGamesOfOrganization,
  editGame,
  getUserDetails,
  addTips,
  addQuestion,
  getSingleGames,
  getSingleGamesAttachment,
  getSingleGamesTip,
  getSingleGamesFinalQuestion,
  getGamesCategoryFag,
  getAllGames,
  getTrendingGames,
  contactus
  
};

export default userService