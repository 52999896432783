import React, { useState } from 'react';
import Header from "../shared/layouts/header";
import {Button, Table} from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent/index";
import { Rating } from 'react-simple-star-rating';
const Leaderboard = () =>{
    const [rating, setRating] = useState(0)

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate)

        // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)
return (
<div className="page-block">
   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">Leaterboard</h1>
     
   </div>
   <Table responsive bordered className="leader-table">
      <thead>
         <tr>
            <th>Range</th>
            <th>Name Game Player</th>
            <th>Star</th>
            <th>Score</th>
         </tr>
      </thead>
      <tbody>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
         <tr>
            <td>
                <img src="/assets/images/medal.svg" alt="medal" width="38" height="38"/>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src="/assets/images/user.svg" width="31" height="31" alt="user" className='me-2'/>Player Name 
                </div>
            </td>
            <td>
            <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size="28"
                />
            </td>
            <td>302521</td>
         </tr>
      </tbody>
   </Table>
   <div className='submit-btn text-start mt-4'>
        <Button variant='secondary'>Submit</Button>
   </div>
</div>
)
}
export default Leaderboard;