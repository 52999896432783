import React, { useRef, useEffect } from 'react';
import { Button, Col, Container, Image, Row, Form, Accordion } from 'react-bootstrap';

import Slider from "react-slick";
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTrendingGames, getGamesCategory } from '../../../store/api/course.api';
import { uniq } from 'lodash';
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import AllCategory from '../AllCategory';
import ContactUs from '../Contact';

const ManinPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allGame, setAllGame] = useState();
    const [allCategory, setAllCategory] = useState();
    const { t, i18n } = useTranslation();
    const testimonialSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    autoplay: true,
                    arrows: false,
                    infinite: true,
                }
            },
        ]
    };
 
    const [startDate, setStartDate] = useState(new Date());

    //   const sliderRef = useRef(null);

    //   sliderRef.current.slickGoTo(2);
    useEffect(() => {
        dispatch(getTrendingGames()).then((res) => {
           
            let temp = res.payload.filter((game) => game.game_script__language ==(i18n.languages[0] === 'sw'?'sv':i18n.languages[0]));
                setAllGame(temp)
        })
        dispatch(getGamesCategory()).then((res) => {

            setAllCategory(res.payload)
        })

    }, [i18n.languages[0]])
    const logoSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
        ]
    };
    const trending = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 1500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },

        ]
    };
    const gotocat=(id) =>{
        navigate("/game-new/" + id);
    }
    const gotosinglegame =(id,game_type,cat_id) => {
        navigate('/game-details-new/'+game_type.toLowerCase()+'/'+cat_id+'/'+id+'/true');
    }
   
    return (
        <>
            <section className='main-banner position-relative'>
                <div className='section-bg-corner  bg-secondary'></div>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='main-banner-content mb-md-0 mb-40'>
                                <h1 className='text-md-start text-center font-poppins fw-bold text-capitalize fs-48 mb-md-38 mb-7'>Platform for <br className='d-none d-lg-block' /> top-class online & offline adventure games</h1>
                                <div className='btn-block text-md-start text-center'>
                                    <Button className='rounded-pill me-md-9 me-4' variant="primary">Our Game</Button>
                                    <Button className='rounded-pill' variant="primary">Review</Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='home-banner-img-wrap text-end'>
                                <Image className='position-relative home-banner-img' src="/assets/images/home-banner1.png" alt='home-banner1' width={582} height={434} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <AllCategory/>

            <section className='trending-section pb-md-150 pb-80' id="trending">
                <Container>
                    <div className='text-center mb-md-70 mb-10'>
                        <p className='fs-18 font-poppins fw-semibold mb-0 text-black-secondary'>Top Selling</p>
                        <h2 className='fs-60 fw-bold text-black-secondary mb-0'>Trending</h2>
                    </div>
                    <div className='overflow-hidden'>
                    <Slider {...trending} className='trending-slider'>
                        {
                            allGame?.map((item, index) => (
                                // Need to add below class dynamic 
                                // dark-cityquest , dark-homequest , dark-officequest , dark-schoolquest , dark-halloween
                               
                               <div className={`trending-card mb-md-0 mb-38 ${'dark-' + item.game_script__game_type.toLowerCase()}`}>
                                    <div className='trending-image'>
                                        <img src={item?.game_script__image} className='img-fit' alt="top-selling" width="314" height="457" />
                                    </div>
                                    <div className='trending-content text-center px-6 pt-7'>
                                        <h5 className='font-poppins fs-24 text-white fw-normal'>{item.game_script__game_type}</h5>
                                        <p>{item.game_script__title}</p>
                                        <p className='fs-16 pt-2 border-top'><ReactMarkdown>{item?.game_script__description}</ReactMarkdown></p>
                                        <Button className='rounded-pill' variant="white" onClick={()=>gotosinglegame(item.game_script__id,item.game_script__game_type,item?.game_script__game_category__games_category_id)} >Read More</Button>
                                    </div>
                                </div>
                                ))}
                    </Slider>
                </div>
                    {/* <Row>
                        <Col md={4}>
                            <div className='trending-card mb-md-0 mb-38'>
                                <div className='trending-image'>
                                    <Image src="/assets/images/trending-1.png" className='img-fit' alt='trendingimg' width={383} height={196} />
                                </div>
                                <div className='trending-content text-center px-6 pt-7'>
                                    <h5 className='font-poppins fs-24 text-nevy-blue fw-normal'>Lorum Ipsum</h5>
                                    <p className='fs-16 pt-2 border-top'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                                    <Button className='rounded-pill' variant="primary">Read More</Button>

                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='trending-card mb-md-0 mb-38'>
                                <div className='trending-image'>
                                    <Image src="/assets/images/trending-2.png" className='img-fit' alt='trendingimg' width={383} height={196} />
                                </div>
                                <div className='trending-content text-center px-6 pt-7'>
                                    <h5 className='font-poppins fs-24 text-nevy-blue fw-normal'>Lorum Ipsum</h5>
                                    <p className='fs-16 pt-2 border-top'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                                    <Button className='rounded-pill' variant="primary">Read More</Button>

                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='trending-card mb-md-0 mb-38'>
                                <div className='trending-image'>
                                    <Image src="/assets/images/trending-3.png" className='img-fit' alt='trendingimg' width={383} height={196} />
                                </div>
                                <div className='trending-content text-center px-6 pt-7'>
                                    <h5 className='font-poppins fs-24 text-nevy-blue fw-normal'>Lorum Ipsum</h5>
                                    <p className='fs-16 pt-2 border-top'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                                    <Button className='rounded-pill' variant="primary">Read More</Button>

                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </section>

            <section className='who-we-are-section' id='about-us'>
                <Container>
                    <Row className='flex-md-row flex-column-reverse'>
                        <Col md={6} className='px-40 mt-md-0 mt-11'>
                            <div className='who-we-are-img-wrap'>
                                <Image src='/assets/images/who-we-are.png' className='rounded-5' alt='who-we-are' width={473} height={531}></Image>
                            </div>
                        </Col>
                        <Col md={6} className='ps-xl-11'>
                            <h6 className='text-dark-green'>About Us</h6>
                            <h2 className='fs-60 fw-bold text-black-secondary mb-10'>Who We Are</h2>
                            <p className='fs-24 text-black-secondary lh-sm'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                            <p className='fs-24 text-black-secondary lh-sm'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                            <p className='fs-24 text-black-secondary lh-sm'>PageTraffic offers top-notch SEO and digital marketing solutions to help you rank high on search results, connect with.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='why-choose-us-section bg-light-gray' id="how-it-work">
                <Container>
                    <Row className='flex-md-row flex-column-reverse'>
                        <Col md={6} className='px-lg-40 px-5 mt-md-0 mt-11'>
                            <div className='why-choose-us-img-wrap'>
                                <Image src='/assets/images/why-choose-us.png' className='rounded-5' alt='why-choose-us' width={581} height={547}></Image>
                            </div>
                        </Col>
                        <Col md={6} className='ps-lg-11'>
                            <h6 className='text-black-secondary'>About Us</h6>
                            <h2 className='fs-60 fw-bold text-black-secondary mb-10'>Why Choose us</h2>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className='accordion-green'>
                                    <Accordion.Header className='fs-28'>Our idea</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                        Our business idea is to provide our customers with easy and affordable access to the top class on & offline adventure games and events on a daily basis. We create a subscription service for group activities which will help to improve the level of social awareness.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='accordion-blue'>
                                    <Accordion.Header className='fs-28'>Vision</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                        Our vision is to create a simple tool based on group activities for socializing in real life in a society overloaded with online communication.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='accordion-orange'>
                                    <Accordion.Header className='fs-28'>Mission</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                        Our mission is to increase the level of social interaction among people with the help of the technology we use in everyday life.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>


            <div className='customer-section font-poppins bg-white'>
                <Container>
                    <h2 className='mb-2 fs-24 fw-medium text-center font-poppins mb-4'>Trusted by over happy customers, including</h2>

                    <Slider {...logoSlider} className='logos-block logo-slider'>
                        <div className='logo-box'>
                            <Image src="/assets/images/forbe-logo.png" alt="forbe" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/netflix.png" alt="netflix" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/amazone.png" alt="amazone" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/sej-logo.png" alt="sej-logo" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/entrepreneur-logo.png" alt="entrepreneur-logo" />
                        </div>
                    </Slider>

                </Container>
            </div>

            <section className='bg-light-gray py-50'>
                <Container>
                    <div className='testimonial-sliderwrapper'>
                        <div className="row">
                            <div className="col-rs-10 offset-rs-1">
                                <h2 class="font-poppins fw-semibold fs-40 mb-1">What Customer say</h2>
                                <p className='fs-20 font-poppins fw-normal text-gray mb-30'>Lorem Ipsum is simply dummy text of the printing.</p>
                                <Slider {...testimonialSlider} className=''>

                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src="/assets/images/testimonial.png" className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('Highly_recommend')}</h5> */}
                                        <div className='testimonial-content'>
                                            <Image className='mb-3 d-lg-block d-none' src="/assets/images/quote.png" alt='quote'></Image>
                                            <p className='text-gray fs-20 mb-20'>{t('Highly_recommend_comment')}</p>
                                            <div className='testimonial-footer align-items-center'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{t('Highly_recommend_poster')}</h6>
                                                    <span className='text-gray fs-20'>Manager @ Howarts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src="/assets/images/testimonial.png" className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('Excellent_service_and_a_great_quest_experience')}</h5> */}
                                        <div className='testimonial-content'>
                                            <Image className='mb-3 d-lg-block d-none' src="/assets/images/quote.png" alt='quote'></Image>
                                            <p className='text-gray fs-20 mb-20'>{t('Excellent_service_comment')}</p>
                                            <div className='testimonial-footer'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{t('Excellent_service_poster')}</h6>
                                                    <span className='text-gray fs-20'>Manager @ Howarts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src="/assets/images/testimonial.png" className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('We_had_a_lot_of_fun_solving_the_puzzles')}</h5> */}
                                        <div className='testimonial-content'>
                                            <Image className='mb-3 d-lg-block d-none' src="/assets/images/quote.png" alt='quote'></Image>
                                            <p className='text-gray fs-20 mb-20'>{t('We_had_a_lot_commnet')}</p>
                                            <div className='testimonial-footer'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{t('We_had_a_lot_poster')}</h6>
                                                    <span className='text-gray fs-20'>Manager @ Howarts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src="/assets/images/testimonial.png" className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('Excellent_service_and_a_great_quest_experience')}</h5> */}
                                        <div className='testimonial-content'>
                                            <Image className='mb-3 d-lg-block d-none' src="/assets/images/quote.png" alt='quote'></Image>
                                            <p className='text-gray fs-20 mb-20'>{t('Excellent_service_comment')}</p>
                                            <div className='testimonial-footer'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{t('Excellent_service_poster')}</h6>
                                                    <span className='text-gray fs-20'>Manager @ Howarts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src="/assets/images/testimonial.png" className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('We_had_a_lot_of_fun_solving_the_puzzles')}</h5> */}
                                        <div className='testimonial-content'>
                                            <Image className='mb-3 d-lg-block d-none' src="/assets/images/quote.png" alt='quote'></Image>
                                            <p className='text-gray fs-20 mb-20'>{t('We_had_a_lot_commnet')}</p>
                                            <div className='testimonial-footer'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{t('We_had_a_lot_poster')}</h6>
                                                    <span className='text-gray fs-20'>Manager @ Howarts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>


            <section className='contact-container' id="contact">
              <ContactUs></ContactUs>
            </section>

        </>
    )
}

export default ManinPage;