import { Route } from "react-router-dom";
import { Layout } from '../../pages/shared/layouts/layout';
import CreateGame from "../../pages/admin/create_game";
import Invitation from "../../pages/admin/Invitation";
import AdminPanel from "../../pages/admin/AdminPanel";
import CreateEpisode from "../../pages/admin/create_episode";
import Leaderboard from "../../pages/admin/leaderboard";
import GameSteps from "../../pages/admin/GameSteps";


export const adminRoute = (
    <Route path="/admin1" element={<Layout />} >
        {/* <Route path="/admin"> */}
            <Route path='create' element ={<CreateGame />} />
            <Route path='game-detail/:id' element={<GameSteps/>}/>
            <Route path='invitation' element ={<Invitation />} />
            <Route path='admin-panel' element ={<AdminPanel/>} />
            <Route path='create-episode' element ={<CreateEpisode />} />
            <Route path='leaderboard' element ={<Leaderboard />} />
        {/* </Route> */}
    </Route>
)