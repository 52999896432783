import { Button, Col, Container, Image, Row ,Modal,Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import SvgComponent from '../../Components/SvgComponent';
import ProgressBar from "@ramonak/react-progress-bar";
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import React,{ useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const GameConfiguration = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            
            {
              breakpoint: 576,
              settings: { 
                slidesToShow: 1, 
                slidesToScroll: 1, 
                infinite: false ,
                }
            },
            
          ]
      };
      const [value, onChange] = useState(new Date());
    //   const [startDate, setStartDate] = useState(new Date());
      const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const [show1, setShow1] = useState(false);
        const handleClose1 = () => setShow1(false);
        const handleShow1 = () => setShow1(true);
        const [selectedDate, setSelectedDate] = useState(new Date());

        const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
          <button
            className="custom-input"
            onClick={onClick}
            ref={ref}
          >
            {value}
          </button>
        ));
    return(
        <>
         <div className="banner-wrapper bg-primary text-white">
            <Container>
                <div className="block font-24 fw-500 mb-9">
                    <h1 className="font-raleway fw-800 font-58">CityQuest - The city is 
                    your playground!</h1>
                    <p>Real-life detective game for corporate and private events!</p>
                </div>
                <div className="d-flex align-items-center justify-content-center mx-n4">
                    <Link to="/" className="btn btn-info rounded-pill mx-4">Our Game</Link>
                    <Link to="/" className="btn btn-info rounded-pill mx-4">For Companies</Link>
                </div>
                </Container>
            </div>

            <div className='event-details'>
                <Container>
                <h2 className='text-primary fw-bold font-poppins font-32 mb-44'>2. Event Details</h2>
                <Row>
                    <Col md={6}>
                        <div className='text-start font-poppins'>
                    <div className="bg-black overflow-hidden radius-20 height-250 mb-54">
                    <iframe className='w-100 h-100 object-fit' width="560" height="315" src="https://www.youtube.com/embed/onns7q70_ao" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <ul className='d-inline-flex event-buttons align-items-center font-poppins mx-n2 ps-0'>
                        <li  className='badge bg-primary rounded-pill fw-semibold minw-160 mx-2'>Vertual</li>
                        <li className='badge bg-primary rounded-pill fw-semibold  minw-160 mx-2'>Hybrid</li>
                    </ul>
                    <h2 className="border-bottomsmall font-36 font-poppins fw-900">The source for <br/>
                    proven, engaging <br/>
                    email campaigns</h2>
                    <div className="content-wrapper">
                        <p className='fw-semibold'>Love is in the air! Capture your team’s hearts and minds with this virtual scavenger hunt filled with 3 heartwarming rounds of trivia and photo and video challenges.
                        </p>
                        <p>Using your smartphones and the Mobile Adventures app, you and your team will play 3 heartwarming rounds. Each round will feature Valentine’s-themed trivia questions or a series of sweet photo and video challenges! One of our Remote Hosts will guide you through this high-energy activity as you test your Valentine’s knowledge and creative skills.</p>
                    </div>
                    </div>
                    </Col>
                    <Col md={6}>
                        <div className='bg-primary font-poppins radius-20 overflow-hidden text-start'>
                            <h2 className='toplheader fw-semibold text-white mb-0 text-center font-poppins font-36'>
                            Event Summary
                            </h2>
                            <div className='event-datawrapper'>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-center'>
                                        <Col md={4}>
                                            <span>Date</span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <Image src="/assets/images/date.svg" alt="Date" className='flex-shrink-0 me-1' />
                                                    {/* <Calendar onChange={onChange} value={value} /> */}
                                                    <span>Check Availability</span>
                                                </div>
                                                <Button variant="secondary" className="d-inline-flex align-items-center justify-content-center rounded-pill font-poppins fw-normal">
                                                    <SvgComponent className="me-1" width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                                                    <span>Edit</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-center'>
                                        <Col md={4}>
                                            <span>Delivery Style</span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <span>Virtual</span>
                                                </div>
                                                <Button variant="secondary" className="d-inline-flex align-items-center justify-content-center rounded-pill font-poppins fw-normal">
                                                    <SvgComponent className="me-1" width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                                                    <span>Edit</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-center'>
                                        <Col md={4}>
                                            <span>Add-Ons</span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <span>Not Selected</span>
                                                </div>
                                                <Button variant="secondary" className="d-inline-flex align-items-center justify-content-center rounded-pill font-poppins fw-normal">
                                                    <SvgComponent className="me-1" width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                                                    <span>Edit</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-center'>
                                        <Col md={4}>
                                            <span>Support Level</span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <span>Remote Host</span>
                                                </div>
                                                <Button variant="secondary" className="d-inline-flex align-items-center justify-content-center rounded-pill font-poppins fw-normal">
                                                    <SvgComponent className="me-1" width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                                                    <span>Edit</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-center'>
                                        <Col md={4}>
                                            <span>Attendees</span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <span>Participants </span>
                                                </div>
                                                <div>
                                                    <span>Book Now 
                                                    Increase Later</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='text-white event-datablock'>
                                    <Row className='align-items-start'>
                                        <Col xs={4}>
                                            <span>Price Break Down</span>
                                        </Col>
                                        <Col xs={8}>
                                            <div className='d-flex align-items-start justify-content-between'>
                                                <div className='d-inline-flex align-items-center text-end'>
                                                    <span>Participants (x0) <br/>Remote Host<br/> Subtotal<br/> Tax (20%) <br/>Amount Due (inc Tax)</span>
                                                </div>
                                                <div className='text-end'>
                                                    <span>£150.00<br/> Included <br/>£ 150.00 <br/>£30.00<br/> Amount Due (inc Tax)</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </div>
                            <div className='bottom-text event-datablock'>
                                <div className='d-flex text-white mb-30'>
                                    <span>Switch to Another Curreny</span>
                                </div>
                                <div className='d-flex align-items-center mb-9'>
                                    <Button variant="secondary" className='me-2 font-18 rounded-pill px-11' onClick={handleShow}>Continue</Button>
                                    <Button variant="info" className="rounded-pill px-11 font-18" onClick={handleShow1}>Share</Button>
                                </div>
                                <span className='text-white mb-5 d-block'>Don't worry you won't be charged yet, we will take<br/> you to the payment screen next.</span>

                            </div>
                        </div>
                    </Col>
                </Row>
                </Container>
            </div>

            <div className='customer-section font-poppins'>
                <Container>
                    <h2 className='mb-2 font-24 fw-semibold font-poppins'>Trusted by over happy customers, including</h2>
                    <div className='logos-block d-flex justify-content-between flex-wrap align-items-center'>
                        <div className='logo-box'>
                            <Image src="/assets/images/forbe-logo.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/netflix.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/pngegg.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/sej-logo.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/entrepreneur-logo.png" alt="" />
                        </div>
                    </div>
                </Container>
            </div>

            <div className='company-testimonialsection text-start'>
                <Container>
                    <h2 className="font-44">An enterprise template to ramp<br/> up your company website</h2>
                    <Slider {...settings} className='slider-space20'>
                       
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        </Slider>
                </Container>
              
            </div>

            <Modal show={show1} onHide={handleClose1} centered className='date-modal'>
                <Modal.Header closeButton>
                <Modal.Title className='text-center h1 flex-grow-1'>Lets Create your Perfect <br/>Murder  on The Train</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-5'>
                        <div className='modal-set-wrapper mb-30'>
                            <div className="stepper-wrapper">
                                <div class="stepper-item completed">
                                    <div class="step-name">1.Event Date</div>
                                    <div class="step-counter"></div> 
                                </div>
                                <div class="stepper-item completed">
                                    <div class="step-name">2.Delivery Style</div>
                                    <div class="step-counter"></div>
                                </div>
                                <div class="stepper-item active">
                                    <div class="step-name">3.Support Level</div>
                                    <div class="step-counter"></div> 
                                </div>
                                <div class="stepper-item">
                                    <div class="step-name">4.event Add-Ons</div>
                                    <div class="step-counter"></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between fw-bold">
                                <span>Your Event Timezone :  BST, London </span>
                                <span>Your Booking Date : Choose Date</span>
                            </div>
                        </div>
                        <div className="date-wrapper">
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            showTimeSelect
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="Time"
                            //   customInput={<CustomInput />}
                            shouldCloseOnSelect={false}
                            open
                        />
                        <div className='mt-11 ms-4 font-poppins'>
                        <span className="d-block font-16 fw-bold">Murder On the Train </span>
                        <span className="d-block font-16 fw-bold">90 Mins</span>
                        </div>
                        </div>
                        <div className='mx-auto maxw-900'>
         <Form>

            <div>
               <Form.Group className="mb-30" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Question - 1" />
               </Form.Group>
               <Row>
                <Col xs={6}>
                    <Form.Group className="mb-30 w-100" controlId="answer">
                        <Form.Control type="text" placeholder="Answer" />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-30 w-100" controlId="answer">
                        <Form.Control type="text" placeholder="Answer" />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-30 w-100" controlId="answer">
                        <Form.Control type="text" placeholder="Answer" />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-30 w-100" controlId="answer">
                        <Form.Control type="text" placeholder="Answer" />
                    </Form.Group>
                </Col>
               </Row>
               <Form.Group className="mb-30 with-icon" controlId="answer">
                  <Form.Control type="text" placeholder="Answer" />
                  <Button variant='link' className='ms-2 plus-btn d-inline-flex align-items-center justify-content-center'><img src="/assets/images/add-ring.svg" alt="add" /></Button>
               </Form.Group>
            </div>
            <div className='text-center'>
            <Button variant="secondary" type="submit" className='px-11 rounded-pill font-18'>
            Next
      </Button></div>
            </Form>
        </div>
                        {/* <Button variant="secondary" type="submit" className="mt-10 px-11">
                            Next
                        </Button> */}
                    
                </Modal.Body>
            
            </Modal>




            <Modal show={show} onHide={handleClose} centered className='date-modal'>
                <Modal.Header closeButton>
                <Modal.Title className='text-center h1 flex-grow-1'>Lets Create your Perfect <br/>Murder  on The Train</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    

                        <div className='modal-set-wrapper mb-30'>
                            <div class="stepper-wrapper">
                                <div class="stepper-item completed">
                                    <div class="step-name">1.Event Date</div>
                                    <div class="step-counter"></div> 
                                </div>
                                <div class="stepper-item completed">
                                    <div class="step-name">2.Delivery Style</div>
                                    <div class="step-counter"></div>
                                </div>
                                <div class="stepper-item active">
                                    <div class="step-name">3.Support Level</div>
                                    <div class="step-counter"></div> 
                                </div>
                                <div class="stepper-item">
                                    <div class="step-name">4.event Add-Ons</div>
                                    <div class="step-counter"></div>
                                </div>
                            </div>
                        </div>
                        <div className='event-text'>
                            <span>Your Event Timezone :  BST, London </span>
                            <span>Your Booking Date : Choose Date</span>
                        </div>
                        <div className="virtual-section mt-11">
                            <Row>
                                <Col md={4}>
                                    <div className="delivery-card">
                                        <div className="card-head">
                                            <img src="/assets/images/virtual.svg" alt="virtual" width="129" height="94"/>
                                        </div>
                                        <div className="card-content">
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <h3 className="mb-0">Virtual</h3>
                                                <img src="/assets/images/alarm.svg" alt="time" width="20" height="16"/>
                                            </div>
                                            <p className="text-start">
                                            We have a car accident: the victim is a Russian scientist who recently came to Stockholm as a tourist. Did he happen to be in the wrong place at the wrong time or was it all planned? The driver is a regular student who has no
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="delivery-card">
                                        <div className="card-head">
                                            <img src="/assets/images/hybrid.svg" alt="virtual" width="111" height="94"/>
                                        </div>
                                        <div className="card-content">
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <h3 className="mb-0">Hybrid</h3>
                                                <img src="/assets/images/alarm.svg" alt="time" width="20" height="16"/>
                                            </div>
                                            <p className="text-start">
                                            We have a car accident: the victim is a Russian scientist who recently came to Stockholm as a tourist. Did he happen to be in the wrong place at the wrong time or was it all planned? The driver is a regular student who has no
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="delivery-card">
                                        <div className="card-head">
                                            <img src="/assets/images/in-person.svg" alt="virtual" width="151" height="122"/>
                                        </div>
                                        <div className="card-content">
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <h3 className="mb-0">in-Person</h3>
                                                <img src="/assets/images/alarm.svg" alt="time" width="20" height="16"/>
                                            </div>
                                            <p className="text-start">
                                            We have a car accident: the victim is a Russian scientist who recently came to Stockholm as a tourist. Did he happen to be in the wrong place at the wrong time or was it all planned? The driver is a regular student who has no
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-10">
                            <Button variant="secondary" type="submit" className='px-11 rounded-pill font-18'>
                                Next
                            </Button>
                        </div>
                    
                </Modal.Body>
            
            </Modal>
        </>
    )
}

export default GameConfiguration;