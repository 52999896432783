
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import SvgComponent from '../../Components/SvgComponent';
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import React,{ useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {getGames,getCategorywiseGames} from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import HomeBanner from  "../shared/HomeBanner"

const GameDetails = () => {
    const { series,is_single,game_type } = useParams();
    const dispatch = useDispatch();
    const _ = require('lodash');

    const [allGame, setAllGame] = useState({});
    const { t,i18n } = useTranslation();
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            
            {
              breakpoint: 576,
              settings: { 
                slidesToShow: 1, 
                slidesToScroll: 1, 
                infinite: false ,
                }
            },
            
          ]
      };
      const episode = {
        dots: true,
        infinite: allGame?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        responsive: [
            
            {
              breakpoint: 576,
              settings: { 
                slidesToShow: 1, 
                slidesToScroll: 1, 
                infinite: false ,
                }
            },
            
          ]
      };
      const [selectedDate, setSelectedDate] = useState(new Date());

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="custom-input"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  useEffect(() => {
    dispatch(getCategorywiseGames(game_type)).then((res) => {
        if(is_single === 'true') {
            console.log(series)
            let temp = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sw'?'sv':i18n.languages[0]) && game.id === Number(series));
            setAllGame(temp)
            console.log(temp)
            
        } else {
            let temp = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sw'?'sv':i18n.languages[0])&& game.series !== null);
            let AllSeries =  _.groupBy(temp,  'series');
           Object.keys(AllSeries).map(template_name => {
            if(template_name === series) {
                setAllGame(AllSeries[template_name])
            }
           })
        }
      
       })
  }, [i18n.languages[0]]);
  
  const OpenBook = (id) => {
    window.open(`https://newbook.ardi.one/${i18n.languages[0]}?category=${game_type}&id=${id}`,'_blank')
  }
    return(
        <>
         <HomeBanner />

            <div className='gaming-details'>
                <Container>
                <h2></h2>
                <Row>
                    <Col md={6}>
                        <div className='gaming-image'>
                            <img src={allGame[0]?.image} alt="gaming-details" width="543" height="431"/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='gaming-detailes text-left'>
                                <h2 className='border-bottomsmall font-36 font-poppins fw-900'>{allGame[0]?.title}</h2>    
                                <p className='fw-medium font-16'>{allGame[0]?.description}
                                </p>
                                <Link to="/" className="btn btn-primary rounded-pill px-7 minw-160 fw-normal">Read More</Link>
                        </div>
                    </Col>
                </Row>
                </Container>
            </div>


            <div className='episode-slider pink-curve'>
                <div className="episode-section pt-0">
                    <Container>
                    <Slider {...episode}>
                    {
                    allGame && allGame.length >0 && allGame?.map((item, index) => (
                        <div className="episode-card">
                            <div className="episode-icon mb-3 d-flex align-items-center justify-content-center">
                                <img  src={item?.icon? item?.icon:'/assets/images/episode-vector.svg'} alt='episode vector' className={item?.icon?'gameIcon':''}  width="69" height="69"/>
                            </div>
                            
                            
                            <h2>{item?.title}</h2>
                            <h3>{item?.episode ?t('Episode') + item?.episode: t('Single_game')}</h3>
                            <p>{item?.description}</p>
                            <ul>
                                {item?.difficulty &&
                                <li>
                                    <img src="/assets/images/Critical.svg" alt='critical' width="26" height="26" className='me-2'/>
                                    Difficulty: {item?.difficulty} / 10
                                </li>
                                }
                                <li>
                                    <img src="/assets/images/Time.svg" alt='time' width="24" height="24" className='me-2'/>
                                    Game length: {item?.duration} min.
                                </li>
                                <li>
                                    <img src="/assets/images/Pin.svg" alt='Pin' width="24" height="24" className='me-2'/>
                                    Distance: approx. 3,3 km
                                </li>
                                <li>
                                    <img src="/assets/images/user-circle.svg" alt='user-circle' width="24" height="24" className='me-2'/>
                                    Number of players: {item?.min_players_per_team +'-'+ item?.max_players_per_team}
                                </li>
                                <li>
                                    <img src="/assets/images/fire_light.svg" alt='fire-light' width="24" height="24" className='me-2'/>
                                    Area: Norrmalm
                                </li>
                                <li>
                                    <img src="/assets/images/group-share.svg" alt='group-share' width="24" height="24" className='me-2'/>
                                    Meeting point: {item.meeting_point_text}
                                </li>
                            </ul>
                            <Button variant="primary" onClick={()=>OpenBook(item.id)} className="text-start mt-11 rounded-pill minw-160 text-center fw-normal">Book</Button>
                        </div>
                          ))}
                    </Slider>
                </Container>
                </div>
            </div>


            <div className="how-to-play-section px-100">
                <Container>                
                    <h2 className='font-60 text-blue'>How to Play</h2>
                    <div className="play-cards-block d-lg-flex">
                        <div className='play-boxes d-lg-block d-flex align-items-center'>
                            <div className='play-description'>
                                <h3 className="text-truncate text-skyblue">Team Up</h3>
                                <p>Collect your team and make a booking. You have to be 2-5 people per team to play the game. The maximum amount of teams is unlimited. You can play at any time of the day or night.</p>
                            </div>
                            <div className="play-image">
                                <img src="/assets/images/play-img1.png" alt="play-img" width="188" height="215"/>
                            </div>
                        </div>
                        <div className='play-boxes d-lg-block d-flex align-items-center'>
                            <div className="play-image">
                                <img src="/assets/images/play-img2.png" alt="play-img" width="188" height="215"/>
                            </div>
                            <div className='play-description'>
                                <h3 className="text-truncate text-lightgreen">Make a booking</h3>
                                <p>In the booking confirmation, you will receive the game link to our browser-based app which you will use to get additional clues, solve puzzles, and unravel the mystery. Each episode has its own starting point.</p>
                            </div>
                        </div>
                        <div className='play-boxes d-lg-block d-flex align-items-center'>
                        <div className='play-description'>
                                <h3 className="text-truncate text-orange">Make a booking</h3>
                                <p>In the booking confirmation, you will receive the game link to our browser-based app which you will use to get additional clues, solve puzzles, and unravel the mystery. Each episode has its own starting point.</p>
                            </div>
                            <div className="play-image">
                                <img src="/assets/images/play-img3.png" alt="play-img" width="188" height="215"/>
                            </div>
                            
                        </div>
                        <div className='play-boxes d-lg-block d-flex align-items-center'>
                            <div className="play-image">
                                <img src="/assets/images/play-img4.png" alt="play-img" width="188" height="215"/>
                            </div>
                            <div className='play-description'>
                                <h3 className="text-truncate text-yellow">Make a booking</h3>
                                <p>In the booking confirmation, you will receive the game link to our browser-based app which you will use to get additional clues, solve puzzles, and unravel the mystery. Each episode has its own starting point.</p>
                            </div>
                        </div>
                        <div className='play-boxes d-lg-block d-flex align-items-center'>
                        <div className='play-description'>
                                <h3 className="text-truncate text-darkgreen">Make a booking</h3>
                                <p>In the booking confirmation, you will receive the game link to our browser-based app which you will use to get additional clues, solve puzzles, and unravel the mystery. Each episode has its own starting point.</p>
                            </div>
                            <div className="play-image">
                                <img src="/assets/images/play-img5.png" alt="play-img" width="188" height="215"/>
                            </div>
                            
                        </div>

                        
                        {/* <div className="play-image">
                            <img src="/assets/images/play-img3.png" alt="play-img" width="188" height="215"/>
                        </div>
                        <div className="play-image">
                            <img src="/assets/images/play-img4.png" alt="play-img" width="188" height="215"/>
                        </div>
                        <div className="play-image">
                            <img src="/assets/images/play-img5.png" alt="play-img" width="188" height="215"/>
                        </div> */}
                    </div>
                    </Container>
            </div>

            
        </>
    )
}

export default GameDetails;