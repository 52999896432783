import { Button, Col, Container, Image, Row, Card ,Badge } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import { useState,useEffect } from 'react';
import {getGames,getGamesCategoryDetail,getCategorywiseGames} from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import HomeBanner from  "../shared/HomeBanner"

const CategoryDeatails = () => {
    const dispatch = useDispatch();
    const _ = require('lodash');

    const [allGame, setAllGame] = useState({});
    const [allSingleGame, setAllSingleGame] = useState([]);
    const { t,i18n } = useTranslation();
    const { game_type } = useParams();
    const [catDetail, setCatDetail] = useState();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            
            {
              breakpoint: 576,
              settings: { 
                slidesToShow: 1, 
                slidesToScroll: 1, 
                infinite: false ,
                }
            },
            
          ]
      };
      const episode = {
        dots: true,
        infinite: allGame?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        responsive: [
            
            {
              breakpoint: 576,
              settings: { 
                slidesToShow: 1, 
                slidesToScroll: 1, 
                infinite: false ,
                }
            },
            
          ]
      };
      const [startDate, setStartDate] = useState(new Date());
      useEffect(() => {
        dispatch(getCategorywiseGames(game_type)).then((res) => {
         //   setAllGame(res.payload.filter((game) => game.game_type === game_type && game.language == (i18n.languages[0] === 'sw'?'sv':i18n.languages[0])));
            let temp = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sw'?'sv':i18n.languages[0]) && game.series !== null);
            let tempwithnull = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sw'?'sv':i18n.languages[0]) && game.series === null);
            setAllGame(_.groupBy(temp,  'series'))
            setAllSingleGame(tempwithnull)
            
           })
           dispatch(getGamesCategoryDetail(game_type)).then((res) => {
            setCatDetail(res.payload)
              })
      }, [i18n.languages[0]]);

    console.log(allGame)

    return(
        <>
        <HomeBanner />

            <div className='category-details'>
                <Container>
                    <div className='p-70'>
                            <div className='category-image text-start'>
                                <img src={catDetail?.image} alt='category-img'/>
                                <div className='quest-text'>
                                    What is {catDetail?.name}?
                                </div>
                            </div>
                            
                            <p className='text-start pt-10 mb-30'>{catDetail?.description}</p>
                            
                            <div className='d-flex align-items-start'>
                                <Link to={'/user/faq/'+game_type} variant="primary" className='btn btn-primary text-start px-11 rounded-pill'>Faq</Link>
                            </div>
                    </div>
                    
                </Container>
            </div>

            
                
           
            <div className='episode-slider pink-curve'>
            
                <div className="episode-section pt-0">
                    
                    <Container>
                        
                    <Slider {...episode}>
                    {
                    allGame && Object.keys(allGame).map(template_name => (
                        <div className="episode-card">
                            <div className="episode-icon mb-3 d-flex align-items-center justify-content-center">
                                <img  src={allGame[template_name][0]?.icon? allGame[template_name][0]?.icon:'/assets/images/episode-vector.svg'} className={allGame[template_name][0]?.icon?'gameIcon':''} alt='episode vector' width="69" height="69"/>
                            </div>
                            <h2>{allGame[template_name][0]?.series_name}</h2>
                            <h3>{allGame[template_name][0].episode ?t('Number_of_episodes') + allGame[template_name].length: t('Single_game')} </h3>
                           
                            <p>{allGame[template_name][0].description}</p>
                            <ul>
                                {allGame[template_name][0]?.difficulty &&
                                <li>
                                    <img src="/assets/images/Critical.svg" alt='critical' width="26" height="26" className='me-2'/>
                                    Difficulty:  {allGame[template_name][0]?.difficulty} /10
                                </li>
                                }
                                <li>
                                    <img src="/assets/images/Time.svg" alt='time' width="24" height="24" className='me-2'/>
                                    Game length: {allGame[template_name][0]?.duration} min. 
                                </li>
                                <li>
                                    <img src="/assets/images/Pin.svg" alt='Pin' width="24" height="24" className='me-2'/>
                                    Distance: approx. 3,3 km
                                </li>
                                <li>
                                    <img src="/assets/images/user-circle.svg" alt='user-circle' width="24" height="24" className='me-2'/>
                                    Number of players: {allGame[template_name][0].min_players_per_team +'-'+ allGame[template_name][0].max_players_per_team}
                                </li>
                                <li>
                                    <img src="/assets/images/fire_light.svg" alt='fire-light' width="24" height="24" className='me-2'/>
                                    Area: Norrmalm
                                </li>
                                <li>
                                    <img src="/assets/images/group-share.svg" alt='group-share' width="24" height="24" className='me-2'/>
                                    Meeting point: {allGame[template_name][0].meeting_point_text}
                                </li>
                            </ul>
                            <h2>{allGame[template_name][0].price_per_unit?.amount +' '+ allGame[template_name][0].price_per_unit?.currency }</h2>
                            <h3>{'Per'+' '+allGame[template_name][0].pricing_unit} </h3>
                            {allGame[template_name][0].series &&
                            <Link to={'/user/game-details/'+game_type+'/'+allGame[template_name][0].series +'/false'} variant="primary"  className="btn btn-primary text-start mt-11 rounded-pill minw-160 text-center fw-normal">Read More</Link>
                            }
                           
                        </div>
                          ))}
                          {
                    allSingleGame && allSingleGame.map(item => (
                        <div className="episode-card">
                            <div className="episode-icon mb-3 d-flex align-items-center justify-content-center">
                                <img  src={item?.icon? item?.icon:'/assets/images/episode-vector.svg'} alt='episode vector' className={item?.icon?'gameIcon':''} width="69" height="69" />
                            </div>
                            <h2>{item.title}</h2>
                            <h3>{t('Single_game') } </h3>
                           
                            <p>{item.description}</p>
                            <ul>
                                <li>
                                    <img src="/assets/images/Critical.svg" alt='critical' width="26" height="26" className='me-2'/>
                                    Difficulty: {item.min_players_per_team +'-'+ item.max_players_per_team}
                                </li>
                                <li>
                                    <img src="/assets/images/Time.svg" alt='time' width="24" height="24" className='me-2'/>
                                    Game length: 120 min.
                                </li>
                                <li>
                                    <img src="/assets/images/Pin.svg" alt='Pin' width="24" height="24" className='me-2'/>
                                    Distance: approx. 3,3 km
                                </li>
                                <li>
                                    <img src="/assets/images/user-circle.svg" alt='user-circle' width="24" height="24" className='me-2'/>
                                    Number of players: {item.min_players_per_team +'-'+ item.max_players_per_team}
                                </li>
                                <li>
                                    <img src="/assets/images/fire_light.svg" alt='fire-light' width="24" height="24" className='me-2'/>
                                    Area: Norrmalm
                                </li>
                                <li>
                                    <img src="/assets/images/group-share.svg" alt='group-share' width="24" height="24" className='me-2'/>
                                    Meeting point: {item.meeting_point_text}
                                </li>
                            </ul>
                            <h2>{item.price_per_unit?.amount +' '+ item.price_per_unit?.currency }</h2>
                            <h3>{'Per'+' '+item.pricing_unit} </h3>
                            <Link to={'/user/game-details/'+game_type+'/'+item.id+'/true'} variant="primary"  className="btn btn-primary text-start mt-11 rounded-pill minw-160 text-center fw-normal">Read More</Link>
                           
                        </div>
                          ))}
                          
                    </Slider>
                </Container>
                </div>
            </div>

            <div className='company-testimonialsection text-start'>
                <Container>
                    <h2 className='font-44'>An enterprise template to ramp<br/> up your company website</h2>
                    <Slider {...settings} className='slider-space20'>
                       
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        <div className="slide-box ">
                                <p className='testimonial-text font-24'>“Buyer buzz partner network disruptive non-disclosure agreement business”</p>
                                <div className='testimonial-footer d-flex align-items-center'>
                                    <Image src='https://images.pexels.com/photos/11843572/pexels-photo-11843572.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' className='w-58 object-fit rounded-pill me-5 flex-shrink-0' />
                                    <div className='block'>
                                        <h6 className='mb-1'>Albus Dumbledore</h6>
                                        <span>Manager @ Howarts</span>
                                    </div>
                                </div>
                        </div>
                        </Slider>
                </Container>
              
            </div>
            
        </>
    )
}

export default CategoryDeatails;





