import {Button, Form, Modal, Table} from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent/index";
import { getUserDetails } from '../../store/api/course.api';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Invitation = () => {
    const [show, setShow] = useState(false);
    const [allUser, setAlluser] = useState();
    const { organization_id } = useSelector((state) => state.auth.user || {});
    const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
   let body ={
      "organization_id":organization_id
   }
   dispatch(getUserDetails(body)).then((res) => {
      setAlluser(res.payload)
   })

}, []);
    return(
        <div className="page-block">
   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h4 mb-2 mx-1 text-start">Invetation </h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152 justify-content-center">
         <span>Send Invitation</span>
      </Button>
   </div>
   <Table responsive bordered>
      <thead>
         <tr>
            <th>Index</th>
            <th>Email</th>
            <th>First name</th>
            <th>Last Name</th>
            <th>organization</th>
            <th>Active</th>
         </tr>
      </thead>
      <tbody>
      {
         allUser && allUser.length > 0 && allUser?.map((item, index) => (
         <tr>
            <td>{index +1}</td>
            <td>{item?.email}</td>
            <td>{item?.first_name}</td>
            <td>{item?.last_name} </td>
            <td>{item?.organization}</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" onClick={handleShow} className="px-1 d-inline-flex align-items-center justify-content-center text-primary"  onClick={handleShow}>
                     <SvgComponent width={20} height={20} viewBox='0 0 20 20' SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent width={20} height={20} viewBox='0 0 20 20' SvgName="edit" />
                  </Button>
               </div>
            </td>
         </tr>
         ))}
      </tbody>
   </Table>

   <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-center h1 flex-grow-1'>Lorum Ipsum Content </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Form>

            <div>
               <Form.Group className="mb-30" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Question - 1" />
               </Form.Group>
               <Form.Group className="mb-30 with-icon" controlId="answer">
                  <Form.Control type="text" placeholder="Answer" />
                  <Button variant='link' className='ms-2 plus-btn d-inline-flex align-items-center justify-content-center'><img src="/assets/images/add-ring.svg" alt="add" /></Button>
               </Form.Group>
            </div>
            <Button variant="secondary" type="submit">
        Submit
      </Button>
            </Form>
        </Modal.Body>
      
      </Modal>
</div>
    )
}

export default Invitation;