


import { useState, useEffect } from "react";
import { Button, Form, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { editGame,getGamesCategory } from '../../store/api/course.api';
import SvgComponent from "../../Components/SvgComponent";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

const EditGame = (prop) => {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState({ description: "", difficulty: "",episode:0 });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedlang, setselectedlanguage]= useState();
    
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let optionsLanguage = [{ value: 'en', label: 'English' },{ value: 'sv', label: 'Sevika',duration:0,episode:0,difficulty:0 }];
    let optionsgameCategory = [];
    const [selectedGameCategory,setselectedGameCategory]= useState();
    const [allCategory, setAllCategory] = useState();
    const [selectedtc, setSelectedTc] = useState(false);
    const [selectedGameType,setSelectedGameType]= useState();
    const { organization_id } = useSelector((state) => state.auth.user || {});

    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    useEffect(() => {
        
        setShow(prop.show);
        dispatch(getGamesCategory()).then((res) => {
            setAllCategory(res.payload)
         })
         setValue({
            ...value,
            ['title']: prop.editItem.title,
            ['min_players_per_team']: prop.editItem.min_players_per_team,
            ['max_players_per_team']: prop.editItem.max_players_per_team,
            ['episode']: prop.editItem.episode,
            ['rules']: prop.editItem.rules,
            ['available_instantly']: prop.editItem.available_instantly,
            ['meeting_point']: prop.editItem.meeting_point,
            ['meeting_point_text']: prop.editItem.meeting_point_text,
            ['duration']: prop.editItem.duration,
            ['game_finished']: prop.editItem.game_finished,
            ['last_message']: prop.editItem.last_message,
            ['the_end']: prop.editItem.the_end,
            ['video_url']: prop.editItem.video_url,
            ['difficulty']: prop.editItem.difficulty,
            ['description']: prop.editItem.description,
            

          
          }); 
      

    }, []);
    const onChange = (e) => {
        console.log(e.target.name);
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };

    const onAdd = () => {
       


        var data = new FormData();
        data.append('id',prop.editItem.id);
        data.append('title', value.title);
        data.append('language',selectedlang);
        data.append('game_category', selectedGameCategory);
        data.append('game_type', selectedGameType);
        
        data.append('min_players_per_team', value.min_players_per_team);
        data.append('max_players_per_team', value.max_players_per_team);
        data.append('episode', value.episode);
        data.append('rules', value.rules);
        data.append('available_instantly', selectedtc);
        data.append('meeting_point', value.meeting_point);
        data.append('meeting_point_text', value.meeting_point_text);
        data.append('duration', value.duration);
        data.append('game_finished', value.game_finished);
        data.append('last_message', value.last_message);
        data.append('the_end', value.the_end);
        data.append('video_url', value.video_url);
        data.append('difficulty', value.difficulty);
        data.append('description', value.description);
        data.append('organization', organization_id);
        if(selectedFile){
            data.append('image', selectedFile, selectedFile.name);
        }

        if(selectedIcon) {
            data.append('icon', selectedIcon, selectedIcon.name);
        }
       
        
        
       

        dispatch(editGame(data))
            .unwrap()
            .then(() => {
            //    handleClose();

            })
            .catch(() => {
              //  handleClose();
            });

    };
    const onChangelang = selectedOption => {
        setselectedlanguage(selectedOption.value);
     };
     const onChangeCategory = selectedOption => {
        setselectedGameCategory(selectedOption.value);
        setSelectedGameType(selectedOption.label)
     };
     allCategory && allCategory.map(list =>
        optionsgameCategory.push({ value: list.games_category_id, label: list.name })
     )
     const handletc = () => {
        setSelectedTc(!selectedtc);
    };
    return (
        <div className="">
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center h1 flex-grow-1'>Edit info</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-white fw-light block font-22 fw-light">
                    <div class="stepper-wrapper">
                        <div class="stepper-item completed">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item ">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item ">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                    </div>
                    <Form >
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="Title*"  name="title" autoComplete="off" aria-label="title" value={value.title} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicSub">
                        {optionsLanguage &&
                        <Select className="custom-select" options={optionsLanguage} placeholder={t('select Language*')} onChange={onChangelang} />
                         }
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicSub">
                        {optionsgameCategory &&
                        <Select className="custom-select" options={optionsgameCategory} placeholder={t('select category*')} onChange={onChangeCategory} />
                         }
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="min players per team*"  name="min_players_per_team" autoComplete="off" aria-label="title" value={value.min_players_per_team} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="max_players_per_team*"  name="max_players_per_team" autoComplete="off" aria-label="title" value={value.max_players_per_team} onChange={onChange} />
                        </Form.Group>
                        
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="episode"  name="episode" autoComplete="off" aria-label="title" value={value.episode} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="meeting_point"  name="meeting_point" autoComplete="off" aria-label="title" value={value.meeting_point} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="meeting_point_text"  name="meeting_point_text" autoComplete="off" aria-label="title" value={value.meeting_point_text} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="duration"  name="duration" autoComplete="off" aria-label="duration" value={value.duration} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="video_url"  name="video_url" autoComplete="off" aria-label="video_url" value={value.video_url} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicdifficulty">
                            <Form.Control type="text" placeholder="difficulty"  name="difficulty" autoComplete="off" aria-label="difficulty" value={value.difficulty} onChange={onChange} />
                        </Form.Group>

                        
                        
                        <input type="checkbox" name="available_instantly" checked={selectedtc} onChange={handletc} value={value.available_instantly} />
                            <label> &nbsp;{t('available_instantly')}</label>

                        <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                            <Form.Control as="textarea" rows={2} placeholder="rules*"  name='rules' aria-label="rules" value={value.rules} onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                            <Form.Control as="textarea" rows={2} placeholder="game_finished*"  name='game_finished' aria-label="game_finished" value={value.game_finished} onChange={onChange} />
                        </Form.Group>

                        <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                            <Form.Control as="textarea" rows={2} placeholder="last_message*"  name='last_message' aria-label="last_message" value={value.last_message} onChange={onChange} />
                        </Form.Group>

                        <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                            <Form.Control as="textarea" rows={2} placeholder="the_end*"  name='the_end' aria-label="the_end" value={value.the_end} onChange={onChange} />
                        </Form.Group>
                        
                        

                        <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                            <Form.Control as="textarea" rows={2} placeholder="Description"  name='description' aria-label="description" value={value.description} onChange={onChange} />
                        </Form.Group>

                        <Form.Group className="mb-30">
                                        <label className="custom-label my-0" for="file">
                                            <input type="file" id="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                            <span className="custom-input">
                                                <span className="text">Select Image</span>
                                                <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                                            </span>
                                        </label>
                                    </Form.Group>

                                    <Form.Group className="mb-30">
                                        <label className="custom-label my-0" for="file1">
                                            <input type="file" id="file1" onChange={(e) => setSelectedIcon(e.target.files[0])} />
                                            <span className="custom-input">
                                                <span className="text">Select Icon</span>
                                                <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                                            </span>
                                        </label>
                                    </Form.Group>
                                    
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between text-center d-block">
                    <Button variant="secondary" className="rounded-pill px-11" onClick={onAdd}>
                        Edit
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditGame


