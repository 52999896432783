import React, { useEffect,useState } from 'react';
import {getGamesCategoryDetail} from '../../store/api/course.api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';
import {  Container } from 'react-bootstrap';

export const HomeBanner =() => {
    const [catDetail, setCatDetail] = useState();
    const { game_type } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGamesCategoryDetail(game_type)).then((res) => {
            setCatDetail(res.payload)
              })
    }, [])
return (
<div className="banner-wrapper bg-primary text-white">
            <Container>
                <div className="block font-24 fw-500 mb-9">
                    <h1 className="font-raleway fw-800 font-58">{catDetail?.title}</h1>
                    <p>{catDetail?.subtitle}</p>
                </div>
                <div className="d-flex align-items-center justify-content-center mx-n4">
                    <Link to="/" className="btn btn-info rounded-pill mx-4">Our Game</Link>
                    <Link to="/" className="btn btn-info rounded-pill mx-4">FOR COMPANIES</Link>
                </div>
                </Container>
            </div>


)
}

export default HomeBanner