


import { useState, useEffect } from "react";
import EditGame from "./EditGame";
import EditGameTip from "./EditGameTip"
import EditGameFinalQuestion from './EditGameFinalQuestion';
import EditGameAttachment from './EditGameAttachment';
import { useParams } from 'react-router-dom';
import { getSingleGames,getSingleGamesAttachment,getSingleGamesTip,getSingleGamesFinalQuestion } from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Table} from 'react-bootstrap';
import moment from 'moment'
import SvgComponent from "../../Components/SvgComponent/index";

const GameSteps = (prop) => {
  
    const dispatch = useDispatch();
    const { id } = useParams();
    const [finalquestions,setFinalquestions] = useState([]);
    const [attachment,setattachment] = useState([]);
    const [tips, settips] = useState([]);
    const [tipShow,setTipShow] =useState(false);
    const [questionShow,setQuestionShow] =useState(false);
      const [attachmentShow,setAttachmentShow] =useState(false);
    useEffect(() => {
     
       dispatch(getSingleGames(id)).then((res) => {
        console.log(res)
        //setAllGames(res.payload)
        
     })
     dispatch(getSingleGamesFinalQuestion(id)).then((res) => {
        setFinalquestions(res.payload)
       
     })
     dispatch(getSingleGamesTip(id)).then((res) => {
        settips(res.payload)
       
     })
     dispatch(getSingleGamesAttachment(id)).then((res) => {
        setattachment(res.payload)
       
     })
   
      

    }, []);
   
    const callbackModal = () => {
      //  prop.callbackModal();
       
       setTipShow(false);
       setQuestionShow(false);
       setAttachmentShow(false);
     }
     const openDialoag = (tabname) => {
      console.log(tabname)
      if(tabname ==='tip') {
         setTipShow(true);
      }
      if(tabname ==='question') {
         setQuestionShow(true);
      }
      if(tabname ==='attachment') {
         setAttachmentShow(true);
      }
     
     }
  
    return (
        <div className="page-block">

             {/* attachment */}
<div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">Attachments</h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152" onClick={()=>openDialoag('attachment')} >
         <SvgComponent SvgName="add" className="me-1" />
         <span>ADD Attachment</span>
      </Button>
     
   </div>
<Table responsive bordered>
      <thead>
         <tr>
            <th>TITLE </th>
            <th> Data</th>
            <th>VISIBLE</th>
           
            <th>Date</th>
            <th>Edit Formate</th>
         </tr>
      </thead>
      <tbody>
      {
                            attachment && attachment.length > 0 && attachment?.map((item, index) => (
         <tr key={item?.id}>
            <td>{item.title}</td>
            <td>{item.data}</td>
            <td>{item.visble}</td>
            
            <td>{moment(item?.timestamp).format("DD-MMM-YYYY")}</td>
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary" >
                     <SvgComponent SvgName="edit" />
                  </Button>
                  
               </div>
            </td>
         </tr>
                            ))}
      </tbody>
   </Table>

{/* final Question */}

   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">Final Question</h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152" onClick={()=>openDialoag('question')} >
         <SvgComponent SvgName="add" className="me-1" />
         <span>ADD Question</span>
      </Button>
     
   </div>
<Table responsive bordered>
      <thead>
         <tr>
            <th>question </th>
            <th> answer</th>
            <th>answer1</th>
           
            <th>answer2</th>
            <th>answer3</th>
            <th>answer4</th>
            <th>required_tips</th>
            <th>Action</th>
         </tr>
      </thead>
      <tbody>
      {
                            finalquestions && finalquestions.length > 0 && finalquestions?.map((item, index) => (
         <tr key={item?.id}>
            <td>{item.question}</td>
            <td>{item.answer}</td>
            <td>{item.answer1}</td>
            <td>{item.answer2}</td>
            <td>{item.answer3}</td>
            <td>{item.answer4}</td>
            <td>{item.required_tips}</td>
            
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary" >
                     <SvgComponent SvgName="edit" />
                  </Button>
                  
               </div>
            </td>
         </tr>
                            ))}
      </tbody>
   </Table>


   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">Tips</h1>
      <Button variant='secondary' className="mb-2 mx-1 d-inline-flex align-items-center minw-152" onClick={()=>openDialoag('tip')} >
         <SvgComponent SvgName="add" className="me-1" />
         <span>ADD Tips</span>
      </Button>
     
   </div>
<Table responsive bordered>
      <thead>
         <tr>
            <th>question </th>
            <th> ALTERNATIVES</th>
            <th>HINT1</th>
           
            <th>HINT2</th>
            <th>answer</th>
            <th>USELESS</th>
            
            <th>Action</th>
         </tr>
      </thead>
      <tbody>
      {
                            tips && tips.length > 0 && tips?.map((item, index) => (
         <tr key={item?.id}>
            <td>{item.question}</td>
            <td>{item.synonyms}</td>
            <td>{item.hint1}</td>
            <td>{item.hint2}</td>
            <td>{item.answer}</td>
            <td>{item.useless}</td>
            
            
            <td>
               <div className="d-inline-flex align-items-center justify-content-center">
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary">
                     <SvgComponent SvgName="delete" />
                  </Button>
                  <Button variant="link" className="px-1 d-inline-flex align-items-center justify-content-center text-primary" >
                     <SvgComponent SvgName="edit" />
                  </Button>
                  
               </div>
            </td>
         </tr>
                            ))}
      </tbody>
   </Table>



          
             {tipShow &&
            <EditGameTip show={tipShow} editItem={id} callbackModal={callbackModal} />
           }
           {questionShow &&
            <EditGameFinalQuestion show={questionShow} editItem={id} callbackModal={callbackModal} />
           }
           {attachmentShow &&
            <EditGameAttachment show={attachmentShow} editItem={id} callbackModal={callbackModal} />
           }
        </div>
    )
}

export default GameSteps


