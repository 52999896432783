


import { useState, useEffect } from "react";
import { Container, Form, Button, Image,Modal,Row, Col} from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { addTips } from '../../store/api/course.api';
import SvgComponent from "../../Components/SvgComponent";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

const EditGameAttachment = (prop) => {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState({ description: "", difficulty: "",episode:0 });
    const [selectedFiles, setSelectedFile] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedlang, setselectedlanguage]= useState();
    
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let optionsLanguage = [{ value: 'en', label: 'English' },{ value: 'sv', label: 'Sevika',duration:0,episode:0,difficulty:0 }];
    let optionsgameCategory = [];
    const [selectedGameCategory,setselectedGameCategory]= useState();
    const [allCategory, setAllCategory] = useState();
    const [selectedtc, setSelectedTc] = useState(false);
    const [selectedGameType,setSelectedGameType]= useState();
    const { organization_id } = useSelector((state) => state.auth.user || {});

    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }

    const [formValues, setFormValues] = useState([{ question: "",answer:"",synonyms:"",useless: false,script: prop.editItem.id}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
        
      }
      
    let addFormFields = () => {
        setFormValues([...formValues, { question: "", answer:"",synonyms:"", useless: false,script: prop.editItem}])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    

    useEffect(() => {
        setShow(prop.show);
        console.log("Dfg")
    }, []);
  

   const addTipsFinal = () => {

    dispatch(addTips(formValues))
    .unwrap()
    .then(() => {
    //    handleClose();

    })
    .catch(() => {
      //  handleClose();
    });

   }
   
    return (
        <>
        <div className="">
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center h1 flex-grow-1'>Attachment </Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-white fw-light block font-22 fw-light">
                    <div class="stepper-wrapper">
                        <div class="stepper-item completed">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item completed">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item ">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                        <div class="stepper-item">
                            <div class="step-counter"></div>
                            <div class="step-name"></div>
                        </div>
                    </div>
                    <div className='max-950 overflow-hidden'>
                  <Form  >
                  
                  {formValues.map((element, index,row) => (
                     <div className="form-inline" key={index}>
                    
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
                        <Form.Control type="text" name="question" placeholder={t('question')} autoComplete="off" aria-label="Title" value={element.question || ""}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formsynonyms">
                        <Form.Control type="text" name="synonyms" placeholder={t('synonyms')} autoComplete="off" aria-label="Title" value={element.synonyms || ""}
                        onChange={e => handleChange(index,e)} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formhint1">
                        <Form.Control type="text" name="hint1" placeholder={t('hint1')} autoComplete="off" aria-label="Title" value={element.hint1 || ""}
                        onChange={e => handleChange(index,e)} />
                     </Form.Group>
                     
                     
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
                        <Form.Control as="textarea" rows={2}  name="answer" placeholder={t('answer')} autoComplete="off" aria-label="Title" value={element.answer || ""}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                    
                     <div className='d-flex align-items-center mb-10 wow fadeInDown'>
                     
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formsynonyms">
                     <input type="checkbox" name="useless"   onChange={e => handleChange(index,e)}  value={value.useless} />
                            <label> &nbsp;{t('useless')}</label>
                     
                     </Form.Group>

                        <div className="plus-minusbtn d-flex flex-shrink-0">
                        {index + 1 === row.length &&
                           <a role="button" className='btn btn-link' onClick={() => addFormFields()}>
                              <SvgComponent width={26} height={26} viewBox='0 0 26 26' SvgName="add" />
                           </a>
                           }
                           {index + 1 !== row.length &&
                            <a role="button" className='btn btn-link' onClick={() => removeFormFields(index)}>
                            <SvgComponent width={26} height={26} viewBox='0 0 26 26' SvgName="delete" />
                         </a>
                           }
                          
                        </div>
                     </div>

                     </div>
                  ))}
                 
                 
               </Form>
                 
               </div>
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between text-center d-block">
                    <Button variant="secondary" className="rounded-pill px-11" onClick={addTipsFinal} >
                        Add
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
        </>
    )
   
}

export default EditGameAttachment


