import Footer from "./footer"
import Header from "./header"
import { Outlet } from "react-router"
import Sidebar from './Sidebar';
export const Layout = () =>{

    return (
        <div>
            <Header/>
            <div className="page-wrapper d-flex align-items-start">
            <Sidebar />
            <Outlet/>
            </div>
            {/* <Footer/> */}
        </div>
    )

}