import Header from "../shared/layouts/header";
import {Button, FormControl, FormGroup, FormLabel, Table,Form,Row,Col} from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent/index";
const CreateEpisode = () =>{
return (
<div className="page-block">
   <div className='d-flex align-items-center justify-content-between mb-5 mx-n1'>
      <h1 className="text-primary h6 mb-2 mx-1 text-start">Enter Game Name</h1>
   </div>
   <div className="episode-form">
    <Form>
        <Row>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Select Game" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Enter Duration" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file">
                    <input type="file" id="file"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file1">
                    <input type="file" id="file1"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file2">
                    <input type="file" id="file2"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
             </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file3">
                    <input type="file" id="file3"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file4">
                    <input type="file" id="file4"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file5">
                    <input type="file" id="file5"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
            <Col md={12}>
               <label className="custom-label mb-sm-5 mb-3" for="file6">
                    <input type="file" id="file6"/>
                    <span className="custom-input">
                        <span className="text">Slect Your File</span>
                        <span className="icon"> <SvgComponent className="me-2" width={27} height={29} viewBox='0 0 27 29' SvgName="upload" />Choose File</span>
                    </span>
                </label>
                <Form.Control type="text" placeholder="Hint" className="mb-3 custom-control"/>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Type Answer Here" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Hint" />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-sm-5 mb-3">
                    <Form.Control type="text" className="custom-control" placeholder="Discription" />
                </Form.Group>
            </Col>
        </Row>
        <div className="btn-submit text-start">
            <Button variant="secondary">Submit</Button>
        </div>
    </Form>
   </div>
</div>
)
}
export default CreateEpisode;