import React,{ useState,useEffect } from 'react';
import { Container, Row, Card, Badge, Col, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import {getTrendingGames} from '../../store/api/course.api';
import { useNavigate, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
const GameList = () => {
    const dispatch = useDispatch();
    const [allGame, setAllGame] = useState({});
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
    useEffect(() => {
        dispatch(getTrendingGames()).then((res) => {
            let temp = res.payload.filter((game) => game.game_script__language ==(i18n.languages[0] === 'sw'?'sv':i18n.languages[0]));
                setAllGame(temp)
          
           })
      }, [i18n.languages[0]]);

      const gotosinglegame =(id,game_type,cat_id) => {
        navigate('/game-details-new/'+game_type.toLowerCase()+'/'+cat_id+'/'+id+'/true');
    }
    return(
        <>
 <div className="banner-wrapper bg-primary text-white">
    <Container>
                <div className="block font-24 fw-500 mb-9 text-center">
                    <h1 className="font-raleway fw-800 font-58">Best Low-Cost Team <br/>Building Activities</h1>
                    <p>Real-life detective game for corporate and private events!</p>
                </div>
                <div className="d-flex align-items-center justify-content-center mx-n3">
                    <Link to="/" className="btn btn-white rounded-pill mx-3">Our Game</Link>
                    <Link to="/" className="btn btn-white rounded-pill mx-3">For Companies</Link>
                </div>
                </Container>
            </div>
        <div className="section-block gamelisting-section">
        <Container>
            <Row className="g-11">
            {
                    allGame && allGame.length >0 && allGame?.map((item, index) => (
                <Col md={4} key={index}>
                    <Card className='h-100'>
                        <div className="height-220">
                            <Card.Img variant="top" src={item?.game_script__image} className="w-100 h-100 object-fit" />
                        </div>
                        {/* {item?.image} */}
                    <Card.Body className="text-start font-16 text-black fw-medium">
                        <div className="d-xl-flex align-items-center justify-content-between mb-2">
                            <Card.Title className="h5 fw-800 mb-1">{item?.game_script__title}</Card.Title>
                            <Badge bg="secondary" className="flex-shrink-0 font-secondary fw-normal minw-90 rounded-pill font-14 flex-shrink-0 mb-1">{item?.game_script__game_type}</Badge>
                        </div>
                        <Card.Text>
                        <ReactMarkdown>{item?.game_script__description}</ReactMarkdown>
                        </Card.Text>
                        <Button variant="link" className="font-16 fw-800 text-decoration-none text-primary" onClick={()=>gotosinglegame(item.game_script__id,item.game_script__game_type,item?.game_script__game_category__games_category_id)}>Learn More </Button>
                    </Card.Body>
                    </Card>
                </Col>
                 ))}
            </Row>
            
        </Container>
    </div>
        </>
    )
}

export default GameList;