import React, { useEffect } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import axios from "../../services/httpService";


import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGamesCategory } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import SvgComponent from "../../Components/SvgComponent";

const AllCategory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        dispatch(getGamesCategory()).then((res) => {
            setAllCategory(res.payload)
        })

    }, [i18n.languages[0]])


    const gotocat = (id, name) => {
        //  navigate(`/${name}/` + id);
        window.open(`/${name}/` + id);
    }
    const OpenBook = (game_type) => {
        window.open(`${axios.Base}${i18n.languages[0]}?type=${game_type}`, '_blank')
    }
    return (
        <>
            <section className='game-produce-section'>
                <Container>
                    <div className='d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-center mb-8'>
                        <h2 className='mb-md-10 mb-9 fs-48 font-poppins fw-bold text-capitalize text-center'><span className='text-primary'>What Kind  Of</span> <br className='d-none d-lg-block' /> Quester Are You? </h2>
                        {/* <Link className='btn btn-link pb-2 fs-20'>
                            <span className='me-2'>See All Services</span>
                            <svg className='btn-link-icon' width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 7H25.5M25.5 7L19.875 1M25.5 7L19.875 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Link> */}
                    </div>
                    <Row className='g-xxl-11 g-7'>
                        {
                            allCategory && allCategory.length > 0 && allCategory?.map((item, index) => (
                                <Col lg={4} md={6} className='mb-3'>
                                    <div className='h-100 d-flex flex-column cursor-pointer' onClick={() => gotocat(item.games_category_id,item.name.toLowerCase())}>
                                        <div className={`top-game-boxicon  flex-shrink-0 d-flex align-items-center border-0 ${'bg-light-' + index}`}>
                                            <SvgComponent width={20} height={26} viewBox='0 0 37 46' SvgName="appsvg" className={`svg-game-ic ${item.name.toLowerCase()}` + 'svg'} />
                                            <h6 className='ms-2 mb-0 mt-0 fs-20'>{item.name}</h6>
                                        </div>
                                        <div className={`game-box h-100 border-0 ${'bg-light-' + index}`}>

                                            <div className='game-box-img'>
                                                <Image src={item?.image} className='img-fit' alt='ardi-game'></Image>
                                            </div>
                                            <div className='p-7'>
                                                <h3 className='fs-32 text-black-ternary mb-1'>{item.name}</h3>
                                                <p className='fs-18 text-black-ternary mb-0'>{item.title} {item.subtitle}</p>
                                            </div>

                                            {/* <div className='btn-block'>
                                            <Button className='rounded-pill me-7' variant="success" onClick={() => gotocat(item.games_category_id,item.name.toLowerCase())}>Read More</Button>
                                            <Button className='rounded-pill' variant="success" onClick={()=>OpenBook(item.name.toLowerCase())}>Book</Button>
                                        </div> */}
                                        </div>
                                    </div>
                                </Col>
                            ))}
                    </Row>

                    {/* <Row className='g-10'>
                        {
                            allCategory && allCategory.length > 0 && allCategory?.map((item, index) => (
                                <Col md={6}>
                                    <div className={`game-box h-100 rounded-4 py-lg-52 px-lg-11 p-10 ${'bg-light-' + index}`}>
                                        <span className='game-boxicon rounded-3 mb-xxl-40 mb-7'>
                                            <SvgComponent width={79} height={79} viewBox='0 0 37 46' SvgName="appsvg" className={`svg-game-ic ${item.name.toLowerCase()}` + 'svg'} />
                                        </span>
                                        <h3 className='fs-40 text-black-ternary'>{item.name}</h3>
                                        <p className='fs-18 text-black-ternary'>{item.title} {item.subtitle}</p>
                                        <div className='btn-block'>
                                            <Button className='rounded-pill me-7' variant="success" onClick={() => gotocat(item.games_category_id,item.name.toLowerCase())}>Read More</Button>
                                            <Button className='rounded-pill' variant="success" onClick={()=>OpenBook(item.name.toLowerCase())}>Book</Button>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                    </Row> */}
                </Container>
            </section>

        </>
    )
}

export default AllCategory;