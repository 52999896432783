import React, { useEffect,useState } from 'react';
import {getGamesCategoryFag} from '../../store/api/course.api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {Accordion} from "react-bootstrap";

import HomeBanner from "../shared/HomeBanner";
export const Faq =() => {
    const { game_type } = useParams();
    const dispatch = useDispatch();
        const [allfaq, setAllfaq] = useState();
    useEffect(() => {
        dispatch(getGamesCategoryFag(game_type)).then((res) => {
            setAllfaq(res.payload)
              })
    }, [])

return (
    <div>
     <HomeBanner />
    <div className="ct-container-920 ">
        <div className=" pt-10 p-70 pb-10">
            <div className="tabing-question mb-40">
            <Accordion defaultActiveKey="0" className="mb-40 accordion-faq">
                {
                    allfaq && allfaq.length > 0 && allfaq?.map((item, index) => (
                            <Accordion.Item eventKey={index} className="mb-30 accordion-item-faq" key={index}>
                                <Accordion.Header className='accordion-button-faq'>
                                    {item?.question}
                                </Accordion.Header>
                                <Accordion.Body >
                                    <p>{item?.answer}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                              ))}
                            
                        </Accordion>
            </div>
        </div>
    </div>
   
</div>

)
}

export default Faq